
#navigator[data-v-06309f05] {
  width: 350px;
  /* height: 120px; */
  /* position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2; */
}
.pannel-title[data-v-06309f05] {
  height: 32px;
  border-top: 1px solid #dce3e8;
  border-bottom: 1px solid #dce3e8;
  background: #ebeef2;
  color: #000;
  line-height: 28px;
  padding-left: 12px;
}
