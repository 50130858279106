
.toolbar[data-v-6dddc95b] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* padding: 8px 0px; */
  width: 100%;
  border: 1px solid #e9e9e9;
  /* height: 42px; */
  z-index: 3;
  -webkit-box-shadow: 0px 8px 12px 0px rgba(0, 52, 107, 0.04);
          box-shadow: 0px 8px 12px 0px rgba(0, 52, 107, 0.04);
  position: absolute;
}
.toolbar .command[data-v-6dddc95b]:nth-of-type(1) {
  margin-left: 24px;
}
.toolbar .command[data-v-6dddc95b] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 27px;
  height: 27px;
  margin: 0px 6px;
  border-radius: 2px;
  padding-left: 4px;
  display: inline-block;
  border: 1px solid rgba(2, 2, 2, 0);
}
.toolbar .command[data-v-6dddc95b]:hover {
  cursor: pointer;
  border: 1px solid #e9e9e9;
}
.toolbar .disable[data-v-6dddc95b] {
  color: rgba(0, 0, 0, 0.25);
}
.toolbar .separator[data-v-6dddc95b] {
  margin: 4px;
  border-left: 1px solid #e9e9e9;
}
