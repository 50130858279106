
.detailpannel[data-v-15fd7993] {
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  right: 0px;
  z-index: 2;
  background: #f7f9fb;
  width: 0px;
  border-left: 1px solid #e6e9ed;
}
.detailpannel .block-container[data-v-15fd7993] {
  padding: 16px 8px;
}
.block-container .el-col[data-v-15fd7993] {
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}
.pannel-title[data-v-15fd7993] {
  height: 32px;
  border-top: 1px solid #dce3e8;
  border-bottom: 1px solid #dce3e8;
  background: #ebeef2;
  color: #000;
  line-height: 28px;
  padding-left: 12px;
}
[data-v-15fd7993] .el-input__inner {
  height: 30px;
}
