
.context-menu[data-v-46f05f4e] {
  position: absolute;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 5px 0;
  z-index: 1;
  display: none;
}
.context-menu li[data-v-46f05f4e] {
  cursor: pointer;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
.context-menu li[data-v-46f05f4e]:hover {
  background-color: #f5f7fa;
}
