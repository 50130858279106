
.itempannel[data-v-7679e438] {
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: 2;
  background: #f7f9fb;
  width: 200px;
  padding-top: 8px;
  border-right: 1px solid #e6e9ed;
}
.itempannel ul[data-v-7679e438] {
  padding: 0px;
  padding-left: 16px;
}
.itempannel li[data-v-7679e438] {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  width: 150px;
  height: 28px;
  line-height: 26px;
  /* padding-left: 8px; */
  border: 1px solid rgba(0, 0, 0, 0);
  list-style-type: none;
}
.itempannel li[data-v-7679e438]:hover {
  background: white;
  border: 1px solid #ced4d9;
  cursor: move;
}
.itempannel .pannel-type-icon[data-v-7679e438] {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
[data-v-7679e438] .el-collapse-item__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 48px;
    line-height: 20px;
    background-color: transparent;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    -webkit-transition: border-bottom-color .3s;
    transition: border-bottom-color .3s;
    outline: none;
}
[data-v-7679e438] .el-collapse-item__content {
  padding-bottom: 0px;
}



